
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        








































































































































































































.select-area {
  &--compact-width {
    width: fit-content;
  }
  &__option {
    &--heading {
      color: #000000;
    }
  }
  &__label,
  &__select {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &__label {
    color: #8A96AC;
    .select-area--has-danger & {
      color: #fc4b6c;
    }
  }
  &__select-wr {
    position: relative;
  }
  &__select {
    height: rem(40px) !important;
    border-radius: rem(6px);
    border: solid 1px #d1dbe4;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    color: #465674;
    padding: 0 rem(30px) 0 rem(10px);
    width: 100%;
    &:disabled {
      background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))
    }
    .select-area--has-danger & {
      border: solid 1px #fc4b6c;
    }
    .select-area--compact-height & {
      height: rem(30px) !important;
    }
    &--enabled {
      background: #fff;
    }
    &--error {
      border: 1px solid #FF3455;
    }
  }
  &__arrow-icon {
    position: absolute;
    top: calc(50% - 6px);
    right: rem(12px);
    width: rem(11px);
    fill: #9DAAC4;
    pointer-events: none;
  }
}
