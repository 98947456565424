
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
        









































































.checkbox {
  margin: 2px 0;

  &__label {
    font-family: "Roboto", sans-serif;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #fff;
  }

  &__divider {
    flex: 1;
    height: 2px;
    background-color: #e9ecef;
    border: none;
    margin: 0;
  }
}
[type=checkbox] + label {
  height: initial;
}

[type=checkbox].filled-in:checked+label:after {
  border: 2px solid #4caf50;
  background-color: #4caf50;
}
